exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-ikigai-js": () => import("./../../../src/pages/ikigai.js" /* webpackChunkName: "component---src-pages-ikigai-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-junior-senior-lead-js": () => import("./../../../src/pages/junior-senior-lead.js" /* webpackChunkName: "component---src-pages-junior-senior-lead-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-progress-js": () => import("./../../../src/pages/progress.js" /* webpackChunkName: "component---src-pages-progress-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-review-jsl-js": () => import("./../../../src/pages/review-jsl.js" /* webpackChunkName: "component---src-pages-review-jsl-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

